.modal_admin {
    position: absolute;
    max-width: 600px;
    padding: 40px;
    margin: 0 auto;
    outline: none;

    .modal-title {
        margin-bottom: 15px;
    }

    .modal-form-window {
        display: grid;

        .modal-form-window-section {
            justify-content: center;
            align-items: center;
        }
    }

    .modal-description {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .modal-error {
        color: $prim-red;
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 14px;
    }

    .modal-success {
        color: #009900;
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 14px;
    }

    .modal-user {
        background-color: #ffffff;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        padding: 15px;
        overflow: auto;
    }

    @media screen and (min-width: 768px) {
        .modal-user {
            height: 600px;
        }
    }

    @media screen and (max-width: 768px) {
        .modal-user {
            height: 400px;
        }
    }

    .modal-select {
        width: 100%;
    }

    .form-control {
        width: 100%;
    }

    .modal-button-bottom {
        margin-top: 15px;
    }

    .modal-select {
        width: 100%;
    }

    .modal-input {
        font-weight: bold;
    }

    .modal-input-deduction input {
        color: #C00 !important;
    }

    .modal-input-label {
        margin-left: 15px !important;
        margin-top: 10px !important;
    }

    .modal-datepicker {
        margin-bottom: 20px;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid $prim-blue;
    }

    .score_input {
        width: 49%;
        float: left;
        margin-right: 1%;
    }

    .score_forfeit_window {
        margin-left: 1%;
        width: 49%;
        float: right;
        margin-top: 20px;
    }

    .modal-subtitle {
        padding-top: 15px;
    }

    .featured-icon-window {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    .featured-icon {
        height: 175px;
        width: 175px;
        color: $sec-color;
    }

    .success-message {
        text-align: center;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .reference-id {
        margin-top: 0px;
        text-align: center;
        color: $sec-color;
        font-style: italic;
        font-size: 14px;
    }

    .window-amount-due {
        background-color: #DDDDDD;
        margin: 0 auto;
        margin-top: 10px;
        padding: 20px;
        width: 300px;

        .amount-title {
            text-align: center;
            color: $prim-color;
            font-weight: bold;
            margin-top: 0px;
            margin-bottom: 5px;
        }

        .amount-due {
            text-align: center;
            color: #00BB00;
            font-weight: bold;
            font-size: 40px;
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    .window-cheque-location {

        .cheque-instructions, .cheque-address {
            text-align: center;
            font-size: 14px;
        }

        .cheque-instructions {
            font-weight: bold;
        }
    }
}