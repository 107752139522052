.errorPage {

    h1 {
        font-size: 40px;
        padding-bottom: 20px;
    }
}

.container-select-organization {
    h1 {
        font-size: 32px;
        padding-bottom: 20px;
    }
}

.organization-list-item {
    cursor: pointer;
    background: #DDDDDD;
    border-radius: 5px;
    border: 2px solid $prim-color;
    margin-bottom: 10px;
    padding: 20px;

    h2 {
        text-decoration: italic;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 5px;
    }
}

.organization-list-item:hover {
    background: $prim-color;
    border-radius: 5px;
    border: 2px solid $sec-color;
    margin-bottom: 10px;
    padding: 20px;

    h2 {
        text-decoration: italic;
        margin-bottom: 15px;
        color: #FFF;
    }

    p {
        margin-bottom: 5px;
        color: #FFF;
    }
}

.container-dashboard {

    .dashboard-noresults {
        text-align: center;
        padding: 0 10px 10px 10px;
        font-size: 18px;
    }

    .organization-info {
        // border-radius: 5px;
        // background-color: $off-white;
        padding: 0px 0px 0px 0px;
        margin-bottom: 20px;
        // border: 1px solid $prim-color;

        h1 {
            font-size: 40px;
            margin-top: 0px;
            padding-bottom: 3px;
            border-bottom: 2px solid $prim-color;
        }
    }

    .upcoming-events {
        border-radius: 5px;
        background-color: #DDDDDD;
        padding: 40px 20px;
        margin-bottom: 20px;
        border: 1px solid $prim-color;

        h2 {
            font-size: 32px;
            margin-top: 0px;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    .group-updates {
        border-radius: 5px;
        background-color: #DDDDDD;
        padding: 40px 20px;
        margin-bottom: 20px;
        border: 1px solid $prim-color;

        h2 {
            font-size: 32px;
            margin-top: 0px;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    .user-updates {
        border-radius: 5px;
        background-color: #DDDDDD;
        padding: 40px 20px;
        margin-bottom: 20px;
        border: 1px solid $prim-color;

        h2 {
            font-size: 32px;
            margin-top: 0px;
            margin-bottom: 20px;
            text-align: center;
        }
    }
    
}

.fee_past_due_label,
.fee_past_due_amount {
    font-size: 14px;
}

.fee_past_due_amount {
    font-weight: bold;
    text-align: right;
}

.fee_past_due_row {
    border-bottom: 2px solid #999999;
}

.list-group-dashboard {

    .list-group-item {
        border-color: $prim-color;
        cursor: pointer;
    }

    .list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .list-group-item:hover {
        background: $prim-color;
        color: $off-white;
    }
}

.btn-dashboard-window {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.dashboard-button-window {
    margin-left: 10px;
    margin-right: 10px;

    .dashboard-panel-button {
        background: $sec-color;
        color: #FFFFFF;
        margin-bottom: 10px;
    }

    .edit-company-button {
        margin-top: 10px;
    }
}

.dashboard-panel {
    background: #FFFFFF;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 150px;

    ul {
        font-size: 14px;
        margin-right: 10px;

        li {
            margin-bottom: 3px;
        }

        a {
            color: $sec-color;
            text-decoration: none;
        }
    }

    h2 {
        font-size: 18px;
        margin-top: 5px;
        margin-bottom: 10px;
        margin-left: 10px;
    }

    .dashboard-alert-icon {
        color: #DD0000;
    }

    .dashboard-green-icon {
        color: #009900;
    }

    .featured-icon-window {
        text-align: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .featured-icon {
        height: 100px;
        width: 100px;
        color: $sec-color;
    }
}

.report-summary-panel {
    border: none !important;

    .report-levy-reported-label {
        margin-left: 10px;
        font-size: 18px;
        font-weight: bold;
    }

    .report-levy-reported-amount {
        font-size: 18px;
        font-weight: bold;
        text-align: right;
    }

    .label-total {
        font-size: 24px !important;
    }

    .report-levy-reported-deduction {
        color: #DD0000;
    }
}

.fees_reported {
    padding-bottom: 20px;
    font-size: 40px;
    color: $prim-color;
    font-weight: bold;
}

.fees_unpaid,
.report_total_accommodations_reporting {
    padding-bottom: 20px;
    color: #DD0000;
    font-size: 40px;
    font-weight: bold;
}

.report_total_accommodations_reporting {
    margin-left: 10px;
    color: $sec-color;
}

.dashboard-contact-panel {
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;

    .divider {
        border-top: 1px solid #e7e7e7;
        margin: 10px;
    }

    .panel-label {
        font-weight: bold;
    }

    h3 {
        margin-top: 0px;
        margin-bottom: 10px;
        color: $prim-color;
    }

    p {
        margin-top: 0px;
        margin-bottom: 3px;
    }

    .corporate-address {
        margin-top: 10px;
        font-size: 12px;
    }
}

.daily-report-window {
    background: #fcfcfc;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    h2 {
        text-align: center;
        font-weight: normal;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .instruction-text {
        text-align: center;
        margin-top: 3px;
        margin-bottom: 10px;
    }

    .occupancy-error-text {
        color: #990000;
        text-align: center;
        margin-top: 3px;
        margin-bottom: 10px;
    }

    .occupancy-success-text {
        color: #009900;
        text-align: center;
        margin-top: 3px;
        margin-bottom: 10px;
    }

    .daily-bookings-input input {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .daily-bookings-link {
        text-align: center;
        font-size: 14px;
        
        a {
            color: #a8a8a8;
        }
    }

    .submit-bookings-button {
        background: $sec-color;
        color: #FFFFFF;
    }
}

.summary-report-total-due {
    background: #999999;
}

.room-bookings-dashboard-title {
    font-weight: normal;
}

.room-bookings-window {
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    padding: 5px 10px;

    h3 {
        font-weight: normal;
        font-size: 28px;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .room-bookings-total {
        font-size: 38px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .room-bookings-change-positive {
        color: #090;
        font-size: 28px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .room-bookings-change-negative {
        color: #900;
        font-size: 28px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .room-bookings-change-neutral {
        font-size: 28px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.alert-item {
    margin-bottom: 6px;

    a {
        cursor: pointer;
    }

    .alert-text {
        color: #DD0000;
        font-size: 11px;
    }
}