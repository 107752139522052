.schedule-calendar {
    width: 100%;
    margin-top: 10px;
    border-color: #F2F2F2;
    border-radius: 5px;
}

.cal-has-active-games {
    background: $prim-green;
    color: #FFFFFF;
}

.cal-has-active-games-needs-scores {
    background: $prim-red;
    color: #FFFFFF;
}

.cal-has-active-games-future {
    background: $prim-orange;
    color: #FFFFFF;
}

.cal-today {
    background: $prim-blue;
    color: #FFFFFF;
}