.container {
    max-width: 60rem;
    margin: 0 auto;
    padding: 0 $size-md;
}

.container-full {
    margin: 0 auto;
    padding: 0 $size-md;
}

.item-block {
    width: 100%;
}