.button-lg {
    background: $purple;
    border: none;
    border-bottom: .6rem solid darken($purple, 10%);
    color: white;
    font-weight: bold;
    font-size: $size-lg;
    width: 100%;
    margin-bottom: $size-xl;
    padding: 2.4rem;
}

.button-lg:disabled {
    opacity: .5;
}

.download-pdf-button {
    margin-top: 15px !important;
    margin-bottom: 15px;
    margin-right: 10px !important;
}

.button {
    // background: $purple;
    // border: none;
    // border-bottom: .3rem solid darken($purple, 10%);
    // color: white;
    // font-weight: normal;
    // padding: $size-sm;
    background-color: #b31119;
    border-color: #b31119;
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
    padding: 6px 12px;
}

.button-delete {
    background: $red;
    border-bottom: .3rem solid darken($red, 10%);
    color: white;
}

.button-clear {
    background: none;
    border: none;
    color: $off-white;
    padding: 0;
}

.button-bottom-stacked {
    margin-bottom: 15px !important;
}

.search-button {
    margin-top: 16px !important;
    height: 50px;
}