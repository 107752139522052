// Variable Colors

$prim-color: #134866;
$sec-color: #38b6ff;
$off-white: #E1EFF6;
$header-bg: #DDDDDD;
$body-bg: #FFFFFF;
$list-bg: #97D2FB;
$purple: #4CB944;
$red: #900;

$prim-red: #CC3433;
$prim-blue: #0E3386;
$prim-white: #FFFFFF;
$prim-orange: #E28413;
$prim-green: #94C595;
$prim-yellow: #FCD0A1;

$size-sm: 1.2rem;
$size-md: 1.6rem;
$size-lg: 3.2rem;
$size-xl: 4.8rem;