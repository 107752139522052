.bg-login {
    background: url('../assets/images/login-bg.jpg') no-repeat center center fixed;
    background-size: cover;
}

// .login-container {
//     h1 {
//         font-size: 40px;
//         text-align: center;
//         color: #000;
//         font-weight: 500;
//         margin-top: 0;
//     }

//     input {
//         padding: 18px 12px !important;
//         font-size: 16px !important;
//         line-height: 24px !important;
//     }
// }

// .text-error {
//     color: #900;
// }

// .login-form {
//     padding: 40px 20px;
//     background: rgba(255, 255, 255, 0.9);
//     border-radius: 5px
// }

.login-form {

    width: auto;
    display: block;
    margin-left: 30;
    margin-right: 30;

    .paper {
        // marginTop: theme.spacing.unit * 8,
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 30px 30px;
        // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    }

    .avatar {
        // margin: theme.spacing.unit,
        margin: 10px;
        background-color: $sec-color;
    }

    .form {
        width: 100%;
        margin-top: 10px;
        // marginTop: theme.spacing.unit,
    }

    .submit {
        // marginTop: theme.spacing.unit * 3,
        
    }

    .submit-prim {
        margin-top: 30px;
        background-color: $prim-blue;
    }

    .submit-sec {
        margin-top: 15px;
        background-color: $prim-red;
    }
}

@media (min-width: 460px) {
    .login-form {
        width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
}