html {
    font-size: 80%;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: $size-md;
    background: $body-bg;
}

h1 {
    font-size: 2rem;
}

a {
    color: $prim-color;
}

button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
}

.pull-right {
    float:right;
}