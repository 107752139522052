.react-date-picker,
.react-datetime-picker {
    width: 100%;
}

.react-date-picker__wrapper,
.react-datetime-picker__wrapper {
    border: 1px solid #ced4da !important;
    border-radius: .3rem !important;
    width: 100%;
}

.rc-time-picker-panel {
    z-index: 10000 !important;
}