.card-header {
    background: $sec-color !important;

    p {
        color: #000000;
    }
}

.card-header-sec {
    background: $prim-green !important;
}