/*      /styles/components/_leftsidebar.scss     */

.sidebar_left {
    .list {
        width: 250px,
    }
    
    .fullList {
        width: auto,
    }
}