.daily-bookings-header-window {

    .daily-bookings-title {
        float: left;
        font-weight: normal;
        font-size: 28px;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .submit-daily-bookings-button {
        float: right;
        background: $sec-color;
        color: #FFFFFF;
        margin-top: 15px;
    }
}

.daily-bookings-window,
.daily-bookings-totals-window,
.annual-comparison-window {

    h2 {
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: normal;
        font-size: 24px;
    }

    .daily-bookings-prev {
        float: left;
        color: $sec-color;
    }

    .daily-bookings-prev:hover,
    .daily-bookings-next:hover {
        color: $prim-color;
    }

    .daily-bookings-next {
        float: right;
        color: $sec-color;
    }
    
}

.daily-bookings-totals-window,
.annual-comparison-window {
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    padding: 5px 10px;
    margin-bottom: 20px;

    .monthly-bookings-title {
        color: $sec-color;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
    }

    .monthly-bookings-number {
        margin-top: 0px;
        font-size: 32px;
        margin-bottom: 5px;
    }

    .monthly-bookings-change {
        margin-top: 0px;
        font-size: 24px;
    }

    .monthly-bookings-change-plus {
        color: #009900;
    }

    .monthly-bookings-change-minus {
        color: #990000;
    }
}

.button-generate-bookings-report {
    background: $sec-color !important;
    color: #FFFFFF !important;
    margin-bottom: 20px !important;
}

.link-generate-bookings-report-container {
    text-align: center;
    border-radius: 4px;
    background: $sec-color !important;
    padding: 8px 0px;
    font-size: 0.9375rem;
    width: 100%;
    margin-bottom: 20px;
}

.link-generate-bookings-report {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.75;
    font-weight: 500;
    text-transform: uppercase;
    color: #FFFFFF !important;
    text-decoration: none;
}

.button-generate-bookings-report:hover {
    background: $prim-color !important;
    color: #FFFFFF !important;
}

.daily-bookings-form-item {
    border-bottom: 1px solid #f1f1f1;
    clear: both;
    height: 60px;

    .daily-bookings-form-date {
        float: left;
        margin-bottom: 0px;
    }

    .daily-bookings-input {
        float: right;

        input {
            text-align: center;
        }
    }

    .daily-bookings-input-occupants {
        margin-left: 5px;
    }
}

.bookings-error-text {
    color: #990000;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 10px;
}

.bookings-success-text {
    color: #009900;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 10px;
}