.btn-fab {
    margin: 0px;
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: fixed !important;
}
.btn-add {
    background: $prim-green !important;
    color: #FFFFFF !important;
}
.btn-edit {
    background: $prim-blue !important;
    color: #FFFFFF !important;
}
.btn-remove {
    background: $prim-red !important;
    color: #FFFFFF !important;
}

.fab-add-game {
    float: right !important;
    margin-top: 15px !important;
    margin-right: 15px !important;
    background: $prim-green !important;
}